import { render } from "./RecommendJoinDone.vue?vue&type=template&id=cdfd4824&scoped=true"
import script from "./RecommendJoinDone.vue?vue&type=script&lang=ts"
export * from "./RecommendJoinDone.vue?vue&type=script&lang=ts"

import "./RecommendJoinDone.vue?vue&type=style&index=0&id=cdfd4824&lang=css"
import "./RecommendJoinDone.vue?vue&type=style&index=1&id=cdfd4824&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-cdfd4824"
/* hot reload */
if (module.hot) {
  script.__hmrId = "cdfd4824"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('cdfd4824', script)) {
    api.reload('cdfd4824', script)
  }
  
  module.hot.accept("./RecommendJoinDone.vue?vue&type=template&id=cdfd4824&scoped=true", () => {
    api.rerender('cdfd4824', render)
  })

}

script.__file = "src/page/recommendEvent/RecommendJoinDone.vue"

export default script