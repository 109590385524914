
import api from '@/common/api';
// import { AxiosResponse } from 'axios';
import promotionMixins from '../../common/mixins/promotion';

export default {
  name: 'RecommendEventJoinDone',
  components: {},
  mixins: [promotionMixins],
  data() {
    return {
      isShowKakaoSuccessModal: false,
    };
  },
  created() {},
  mounted() {
    this.recommendNotification();
  },
  methods: {
    async recommendNotification(): Promise<void> {
      try {
        await api.post('/app/recommend/notification');
      } catch (e) {
        console.error(e);
      }
    },
    showKakaoSuccessModal(): void {
      this.isShowKakaoSuccessModal = !this.isShowKakaoSuccessModal;
      if (this.isShowKakaoSuccessModal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    moveStoreOrSendKakaoMessage(): void {
      const isMobile = () => {
        const user = navigator.userAgent;
        let isCheck = false;

        if (user.indexOf('iPhone') > -1 || user.indexOf('Android') > -1) {
          isCheck = true;
        }

        return isCheck;
      };

      if (isMobile()) {
        window.location.href = 'https://abr.ge/ik5s8m';
      } else {
        api.post(`/app/recommend/handle`).then(() => {
          this.showKakaoSuccessModal();
        });
      }
    },
  },
  computed: {},
};
