<template>
  <div id="tutoringWrap">
    <section id="tutoringContent"></section>
    <main class="page-recommend">
      <section class="main">
        <div class="top">특별 10,000P 적립 완료!</div>
        <p class="title">
          회원가입을
          <span class="showOnlyMobile"><br /></span>
          축하합니다!
        </p>
        <p class="desc">
          지금 바로 튜터링 앱을 다운로드하고
          <span class="showOnlyMobile"><br /></span>
          무료 첫 수업을 진행하세요.
          <br />
          <b>45,OOOP</b>
          가 추가 적립됩니다.
        </p>
      </section>
      <p class="bottom">※ 아래 버튼 클릭 시 앱 설치 링크가 카카오톡 메세지로 전달됩니다.</p>
      <section class="participation">
        <img
          class="titleImg"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/img_tl_03.png"
          alt="title"
          style="margin-bottom: 40px"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how01.png" alt="title" />
          <div class="black">
            <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/stamp.png" alt="title" />
            <p class="text">10,000P 적립</p>
          </div>
        </div>
        <img
          class="down-arrow"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
          alt="arrow"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how02.png" alt="title" />
        </div>
        <img
          class="down-arrow"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
          alt="arrow"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how03.png" alt="title" />
        </div>
        <img
          class="down-arrow"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
          alt="arrow"
        />
        <div class="card">
          <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how04.png" alt="title" />
        </div>
      </section>
      <section class="participationWeb">
        <img
          class="titleImg"
          src="//res.tutoring.co.kr/res/images/evt/com/invite/img_tl_03.png"
          alt="title"
          style="margin-bottom: 40px"
        />
        <div class="container">
          <div class="box">
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how01.png" alt="title" />
              <div class="black">
                <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/stamp.png" alt="title" />
                <p class="text">10,000P 적립</p>
              </div>
            </div>
            <img
              class="down-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
              style="visibility: hidden"
            />
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how04.png" alt="title" />
            </div>
          </div>
          <div class="box">
            <img
              class="right-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
            />
            <img
              class="down-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
              style="visibility: hidden"
            />
            <img
              class="left-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
            />
          </div>
          <div class="box">
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how02.png" alt="title" />
            </div>
            <img
              class="down-arrow"
              src="//res.tutoring.co.kr/res/images/evt/com/invite/ic_arrow_referral.png"
              alt="arrow"
            />
            <div class="card">
              <img class="img270" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_w_how03.png" alt="title" />
            </div>
          </div>
        </div>
      </section>
      <section class="challenge-cta is-info" :class="{ 'ani-bottom-up': bottomSheetUp }" id="challengeCta">
        <!-- l-select 에 is-on 넣을때 얘도 같이 is-on 넣어주세요  -->
        <div class="dim"></div>
        <div class="challenge-cta__wrap">
          <div class="wrapper">
            <div class="btnwrap">
              <button
                type="button"
                class="cta__mobile cta__button tamo-layer tablet-mobile"
                data-status="N"
                @click="moveStoreOrSendKakaoMessage"
              >
                <span class="btnText">첫 수업하고 45,000P 받기</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div v-if="isShowKakaoSuccessModal">
        <div class="layer_comm">
          <div class="dimmed"></div>
          <div class="inner_wrapper">
            <div class="inner_layer">
              <img
                class="msg_image"
                src="//res.tutoring.co.kr/res/images/evt/com/invite/img_modal_kakao.png"
                alt="msg"
              />
              <p class="title">
                지금 카카오톡 메시지로
                <br />
                앱 다운로드 링크가 전달됐어요!
              </p>
              <p class="desc">
                앱에서 무료 첫 수업 받고
                <br />
                4O,OOOP 추가로 받아 가세요!
              </p>
            </div>
            <div class="inner_layer_bottom">
              <div>
                앱 다운로드 링크를
                <br />
                받지 못하셨다면?
              </div>
              <img class="qr" src="//res.tutoring.co.kr/res/images/evt/com/invite/img_modal_qr.png" alt="msg" />
            </div>
            <div class="extra_wrapper">
              <button class="extra_button" @click="showKakaoSuccessModal">
                <img
                  class="close_icon"
                  src="//res.tutoring.co.kr/res/images/img/plans/ic_cp_modal_close.png"
                  alt="close"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import api from '@/common/api';
// import { AxiosResponse } from 'axios';
import promotionMixins from '../../common/mixins/promotion';

export default {
  name: 'RecommendEventJoinDone',
  components: {},
  mixins: [promotionMixins],
  data() {
    return {
      isShowKakaoSuccessModal: false,
    };
  },
  created() {},
  mounted() {
    this.recommendNotification();
  },
  methods: {
    async recommendNotification(): Promise<void> {
      try {
        await api.post('/app/recommend/notification');
      } catch (e) {
        console.error(e);
      }
    },
    showKakaoSuccessModal(): void {
      this.isShowKakaoSuccessModal = !this.isShowKakaoSuccessModal;
      if (this.isShowKakaoSuccessModal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    moveStoreOrSendKakaoMessage(): void {
      const isMobile = () => {
        const user = navigator.userAgent;
        let isCheck = false;

        if (user.indexOf('iPhone') > -1 || user.indexOf('Android') > -1) {
          isCheck = true;
        }

        return isCheck;
      };

      if (isMobile()) {
        window.location.href = 'https://abr.ge/ik5s8m';
      } else {
        api.post(`/app/recommend/handle`).then(() => {
          this.showKakaoSuccessModal();
        });
      }
    },
  },
  computed: {},
};
</script>

<style>
.page-recommend {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page-recommend section {
  flex: 1;
}
.page-recommend .titleImg {
  display: block;
  width: 246px;
  margin: 0 auto;
}
.back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: -14px;
  padding: 0 14px;
}
.main {
  background-color: #9a52ff;
  padding: 140px 30px;
  text-align: center;
  color: #ffffff;
}
.main .top {
  width: 261px;
  background: rgba(99, 38, 184, 0.5);
  border-radius: 15px;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #fed33d;
  display: block;
  margin: 0 auto 30px auto;
  padding: 20px 0;
}
.main .title {
  font-weight: 800;
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 40px;
}
.main .desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.bottom {
  width: 100%;
  padding: 20px 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #fed33d;
  background-color: #371469;
}
.participation {
  background-color: #efe6fb;
  padding: 100px 30px 150px 30px;
}
.participation .card {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #c6b7da;
  border-radius: 30px;
  width: 300px;
  margin: 0 auto;
  position: relative;
  padding: 30px 0;
}
.participation .card img {
  display: block;
  margin: 0 auto;
}
.participation .card .black {
  width: 302px;
  height: 312px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  border-radius: 30px;
}
.participation .card .black img {
  width: 221px;
  height: 136px;
  margin: 0 auto;
  transform: rotate(-10deg);
  margin: 47px auto 30px auto;
}
.participation .card .black .text {
  font-weight: 800;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  color: #fed33d;
}
.participation .card .img270 {
  width: 270px;
}
.participation .down-arrow {
  display: block;
  width: 40px;
  margin: 30px auto;
}
@media (max-width: 991px) {
  .participationWeb {
    display: none;
  }
  .bottom {
    display: none;
  }
  .showOnlyMobile {
    display: inline;
  }
}
@media (min-width: 992px) {
  .participationWeb {
    background-color: #efe6fb;
    padding: 100px 30px 150px 30px;
  }
  .participationWeb .container {
    display: flex;
    justify-content: center;
  }
  .participationWeb .container .box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .participationWeb .container .box .right-arrow {
    display: block;
    width: 40px;
    margin: 30px 25px;
    transform: rotate(270deg);
  }
  .participationWeb .container .box .left-arrow {
    display: block;
    width: 40px;
    margin: 30px 25px;
    transform: rotate(90deg);
  }
  .participationWeb .card {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #c6b7da;
    border-radius: 30px;
    width: 430px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .participationWeb .card img {
    display: block;
    margin: 0 auto;
  }
  .participationWeb .card .black {
    width: 432px;
    height: 312px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    border-radius: 30px;
  }
  .participationWeb .card .black img {
    width: 221px;
    height: 136px;
    margin: 0 auto;
    transform: rotate(-10deg);
    margin: 47px auto 30px auto;
  }
  .participationWeb .card .black .text {
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    color: #fed33d;
  }
  .participationWeb .card .img270 {
    width: 270px;
  }
  .participationWeb .down-arrow {
    display: block;
    width: 40px;
    margin: 30px auto;
  }
  .participation {
    display: none;
  }
  .showOnlyMobile {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
/* 구매하기 cta */
.challenge-cta {
  position: fixed;
  bottom: -100%;
  right: 0;
  left: 0;
  z-index: 50;
  transition: bottom 0.4s;
  @media (max-width: 1199px) {
    & {
      bottom: 0;
    }
  }

  &.ani-bottom-up {
    bottom: 0;
  }

  & .challenge-cta__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(55, 20, 105, 0.8);
    backdrop-filter: blur(20px);
    height: 84px;

    @media (max-width: 1199px) {
      & {
        height: 60px;
        padding: 10px 30px;
      }
    }
    & .wrapper {
      width: 100%;
      max-width: 672px;

      & button {
        display: flex;
        width: 100%;
        max-width: 600px;
        height: 50px;
        background: #fed33d;
        border-radius: 10px;
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #121214;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
        margin: 0 auto;
      }
    }
  }
}
// 하단 버튼 작업
.challenge-cta.is-info {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  color: #000;
  transition: bottom 0.4s;
}

.challenge-cta.is-info .challenge-cta__wrap {
  border-top: none;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .heading {
  display: inline-flex;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin-right: 5px;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .date {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ff1212;
  color: #ffffff;
  padding: 5px 13px;
  border-radius: 20px;
  margin-bottom: 10px;
  line-height: 14px;
  font-size: 13px;
  font-weight: 700;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .time {
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  color: #000;
}

.page-recommend {
  .layer_comm {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .dimmed {
      width: 100%;
      height: 100%;
      background: #1a1a1a;
      opacity: 0.6;
    }
    .inner_wrapper {
      position: absolute;
      top: 54%;
      right: 0;
      left: 0;
      width: 400px;
      margin: 0 auto;
      transform: translateY(-50%);
    }
    .inner_layer {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      background-color: #fff;
      border-radius: 20px 20px 0 0;
      padding: 40px 0;
      margin: 0 auto;
      position: initial;
      width: 100%;
      .msg_image {
        width: 62px;
        height: 50px;
        margin-bottom: 14px;
      }
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #121214;
        margin-bottom: 10px;
      }
      .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #85808d;
      }
    }
    .inner_layer_bottom {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f1f1f1;
      border-radius: 0 0 20px 20px;
      padding: 25px 70px;
      margin: 0 auto;
      position: initial;
      .qr {
        width: 96px;
        height: 96px;
      }
    }
    .extra_wrapper {
      padding: 10px 0;
      text-align: center;
      .extra_button {
        color: #fff;
        background-color: #121214;
        border-radius: 25px;
        font-size: 25px;
        padding: 2px 10px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        .close_icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
